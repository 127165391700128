/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { styled } from "styled-components";
import InfiniteScroll from "../../../../InfiniteScroll";
import Spinner from "../../../../Spinner";
import Loader from "../../../../Loader";
import theme from "../../../../../theme";
import { getNewUsers } from "../../../../../services/reports";
import { sendMessageToExtension } from "../../../../../utils/postToExtension";
import {
  displayDate,
  displayUserRole,
  sortRows,
} from "../../../../../utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateRange } from "../../../../styles";
import { OAUTH_TYPES } from "../../../../../utils/constants";
import { exportToCSV } from "../../../../../utils/export";

const getDefaultStartDate = () => {
  const startDate = new Date();
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  return startDate;
};

function NewUsersTable() {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({
    dateRange: { startDate: getDefaultStartDate() },
  });
  const [sort, setSort] = useState({ sort: null, order: null });

  const tableContRef = useRef(null);

  const columnsList = [
    {
      name: "avatar",
      label: <>&nbsp;</>,
      render: (row) => <Avatar $imageUrl={row.avatar} />,
    },
    { name: "name", label: "Name", sortable: true },
    { name: "email", label: "Email", sortable: true },
    {
      name: "verified",
      label: "Verified",
      sortable: true,
      render: (row) => (row.verified ? "Yes" : "No"),
    },
    {
      name: "role",
      label: "Type",
      sortable: true,
      render: (row) => displayUserRole(row.role),
    },
    { name: "companyName", label: "Company", sortable: true },
    {
      name: "lastLoginAt",
      label: "Last Login",
      sortable: true,
      render: (row) => displayDate(row.lastLoginAt, true),
    },
    {
      name: "createdAt",
      label: "Registered On",
      sortable: true,
      render: (row) => displayDate(row.createdAt, true),
    },
    {
      name: "oauthType",
      label: "Registered oAuth Type",
      sortable: true,
      render: (row) => {
        const { oauthType } = row;
        return oauthType === OAUTH_TYPES.gsuite
          ? "GSuite"
          : oauthType.replace(/^[a-z]/, (match) => match.toUpperCase());
      },
    },
    {
      name: "startSubscription",
      label: "Registered Subscription",
      sortable: true,
    },
    { name: "inviteCode", label: "Invite", sortable: true },
    {
      name: "isInvitedToTeam",
      label: "Invited to Team",
      sortable: true,
      render: (row) => {
        const { isInvitedToTeam } = row;
        if (typeof isInvitedToTeam === "boolean") {
          return isInvitedToTeam ? "Yes" : "No";
        }
        return "";
      },
    },
    {
      name: "postSignUpOnboarding",
      label: "Completed Onboarding",
      sortable: true,
      render: (row) => {
        const { postSignUpOnboarding } = row;
        if (!postSignUpOnboarding) {
          return "";
        }
        return postSignUpOnboarding.completed ? "Yes" : "No";
      },
    },
    {
      name: "onboardingQuestions",
      label: "Onboarding Questions",
      render: (row) => {
        const { postSignUpOnboarding } = row;
        if (!postSignUpOnboarding?.steps?.length) {
          return "";
        }

        const questions = getOnboardingStepValues(
          postSignUpOnboarding.steps,
          "question"
        );

        return (
          <OnboardingSteps>
            {questions.map((question, index) => (
              <OnboardingStep key={index}>{question}</OnboardingStep>
            ))}
          </OnboardingSteps>
        );
      },
    },
    {
      name: "onboardingAnswers",
      label: "Onboarding Answers",
      render: (row) => {
        const { postSignUpOnboarding } = row;
        if (!postSignUpOnboarding || !postSignUpOnboarding.steps) {
          return "";
        }

        const answers = getOnboardingStepValues(
          postSignUpOnboarding.steps,
          "answer"
        );

        return (
          <OnboardingSteps>
            {answers.map((answer, index) => (
              <OnboardingStep key={index}>{answer}</OnboardingStep>
            ))}
          </OnboardingSteps>
        );
      },
    },
    {
      name: "hasCreatedCampaign",
      label: "Created Campaign",
      sortable: true,
      render: (row) => (row.hasCreatedCampaign ? "Yes" : "No"),
    },
    {
      name: "isSendEmailReminderFirstCreateCampaign",
      label: 'Send "Create your first campaign" email',
      sortable: true,
      render: (row) => {
        const { isSendEmailReminderFirstCreateCampaign, hasCreatedCampaign } =
          row;
        if (
          hasCreatedCampaign &&
          typeof isSendEmailReminderFirstCreateCampaign !== "boolean"
        ) {
          return "";
        }
        return isSendEmailReminderFirstCreateCampaign ? "Yes" : "No";
      },
    },
    {
      name: "emailIntegration",
      label: "Email Integration",
      sortable: true,
      render: (row) => {
        const { emailIntegration } = row;
        if (!emailIntegration?.type) {
          return "";
        }
        return [
          emailIntegration.method === "oauth"
            ? "oAuth"
            : emailIntegration.method.replace(/^[a-z]/, (match) =>
                match.toUpperCase()
              ),
          emailIntegration.type === OAUTH_TYPES.gsuite
            ? "GSuite"
            : emailIntegration.type.replace(/^[a-z]/, (match) =>
                match.toUpperCase()
              ),
        ]
          .filter(Boolean)
          .join(" ");
      },
    },
    {
      name: "signature",
      label: "Signature",
      render: (row) => (
        <Signature
          dangerouslySetInnerHTML={{
            __html: row.signature || "",
          }}
        />
      ),
    },
  ].slice(users.some((u) => u.avatar) ? 0 : 1);

  const getLimit = () => {
    const minHeadRowHeight = 43;
    const minBodyRowHeight = 46;
    return Math.max(
      Math.floor(
        (tableContRef?.current?.getBoundingClientRect()?.height -
          minHeadRowHeight) /
          minBodyRowHeight
      ),
      10
    );
  };

  const sortUsers = (rows, sortParams) => {
    const { sort: sortField, order: sortOrder } = sortParams || sort;

    const columnRender = columnsList.find((c) => c.name === sortField)?.render;

    setUsers(sortRows(rows, sortField, sortOrder, columnRender));
  };

  const fetchUsers = async (params) => {
    const data = {
      limit: getLimit(),
      offset: params?.clearAfterSearch ? 0 : offset,
    };

    if (Object.keys(params?.newFilters || filters).length > 0) {
      data.filters = params?.newFilters || filters;
    }

    setIsLoading(!data.offset);

    const result = await getNewUsers(data);

    setIsLoading(false);

    if (result.success) {
      sortUsers(
        params?.clearAfterSearch
          ? result.result.users
          : [...users, ...result.result.users]
      );
      setDataLength(
        params?.clearAfterSearch
          ? result.result.users.length
          : dataLength + result.result.users.length
      );
      setHasMore(result.result.meta.hasMore);
      if (result.result.meta.hasMore) {
        setOffset(params?.clearAfterSearch ? data.limit : offset + data.limit);
      }
    } else {
      setHasMore(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch users",
        },
      });
    }
  };

  const handleClickSort = async (sortField) => {
    let newSort;
    if (sortField !== sort.sort) {
      newSort = { sort: sortField, order: "asc" };
    } else {
      newSort = { ...sort, order: sort.order !== "asc" ? "asc" : "desc" };
    }
    setSort(newSort);
    sortUsers(users, newSort);
  };

  const handleChangeFilter = async (newFilters) => {
    setFilters(newFilters);
    await fetchUsers({ clearAfterSearch: true, newFilters });
  };

  const handleSelectDate = (name, value) => {
    const dateRange = { ...filters.dateRange };
    if (value) {
      if (name === "endDate") {
        value.setHours(23);
        value.setMinutes(59);
        value.setSeconds(59);
        value.setMilliseconds(999);
      }
      dateRange[name] = value;
    } else {
      delete dateRange[name];
    }

    if (!dateRange.startDate && !dateRange.endDate) {
      dateRange.startDate = getDefaultStartDate();
    }

    const newFilters = {
      ...filters,
      dateRange,
    };
    handleChangeFilter(newFilters);
  };

  const fetchByScroll = async () => {
    await fetchUsers({ scrolling: true });
  };

  const fetchOnMount = async () => {
    await fetchUsers();
  };

  useEffect(() => {
    fetchOnMount();
  }, []);

  const withSort = dataLength > 1;

  const exportToFile = () => {
    exportToCSV(columnsList, users, "new-users.csv");
  };

  const getOnboardingStepValues = (steps, propertyName) => {
    return steps.map((step) => step[propertyName]);
  };

  const table = (
    <Table>
      <TableHead>
        <TableRow $bgColor="#f3f2f2">
          {columnsList.map(({ name, label, sortable = false }) => (
            <TableHeadData
              key={name}
              {...(withSort && sortable
                ? {
                    $cursorPointer: true,
                    onClick: () => handleClickSort(name),
                  }
                : null)}
            >
              <TableHeadDataSort>
                {label}
                {withSort && sortable && (
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === name ? `${sort.order}-` : ""
                    }icon.png`}
                    alt="sort"
                  />
                )}
              </TableHeadDataSort>
            </TableHeadData>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {!dataLength ? (
            <TableRow>
              <EmptyTableData colSpan={100}>No users to show</EmptyTableData>
            </TableRow>
          ) : (
            users.map((user, index) => (
              <TableRow
                key={index}
                $borderBottom={`1px solid ${theme.colors.divider_color}`}
              >
                {columnsList.map(({ name, render }) => (
                  <TableData key={`${index}-${name}`}>
                    {render ? render(user) : user[name]}
                  </TableData>
                ))}
              </TableRow>
            ))
          )}
        </React.Fragment>
      </TableBody>
    </Table>
  );

  return (
    <Container $isLoading={isLoading}>
      <Filters>
        <Filter>
          <FilterLabel>Date</FilterLabel>
          <DateRange>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={filters.dateRange.startDate}
              onChange={(date) => handleSelectDate("startDate", date)}
              selectsStart
              startDate={filters.dateRange.startDate}
              endDate={filters.dateRange.endDate}
            />
            <span>-</span>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={filters.dateRange.endDate}
              onChange={(date) => handleSelectDate("endDate", date)}
              selectsEnd
              startDate={filters.dateRange.startDate}
              endDate={filters.dateRange.endDate}
              minDate={filters.dateRange.startDate}
            />
          </DateRange>
        </Filter>
        <OutlineCampaignBtn disabled={isLoading} onClick={exportToFile}>
          Export
        </OutlineCampaignBtn>
      </Filters>

      <Content ref={tableContRef}>
        {isLoading ? (
          <Loader parentSize={true} bgColor="white" padding="0" />
        ) : (
          <OverflowContent id="table_layout">
            {hasMore ? (
              <InfiniteScroll
                dataLength={dataLength}
                next={fetchByScroll}
                hasMore={hasMore}
                scrollableTarget={"table_layout"}
                style={{ overflowY: "hidden" }}
                loader={
                  <Spinner width="60" height="60" margin="20px 0 0 40vw" />
                }
              >
                {table}
              </InfiniteScroll>
            ) : (
              table
            )}
          </OverflowContent>
        )}
      </Content>
    </Container>
  );
}

export default NewUsersTable;

const Container = styled.div`
  height: calc(100% - 55px);
  ${({ $isLoading }) =>
    $isLoading
      ? `position: relative;
         > div:first-child {
           position: absolute;
           background: transparent;
           z-index: 1;
         }`
      : ""}
`;

const Content = styled.div`
  height: calc(100% - 90px);
`;

const OverflowContent = styled.div`
  overflow: auto;
  width: 100%;
  height: fit-content;
  max-height: 100%;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 7px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 7px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SortIcon = styled.img`
  width: 11px;
`;

const Filters = styled.div`
  margin-bottom: 25px;
  display: flex;
  column-gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  .multi-select .dropdown-content {
    z-index: 11;
  }
`;

const FilterLabel = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Avatar = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  ${({ $imageUrl }) =>
    $imageUrl
      ? `
          background-image: url(${$imageUrl});
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : ""};
`;

const Signature = styled.div`
  white-space: nowrap;
`;

const OutlineCampaignBtn = styled.button`
  margin-left: auto;
  margin-top: auto;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  padding: 0 22px;
  height: 40px;
  width: auto;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid #757678;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  ${({ disabled }) =>
    disabled
      ? ""
      : `
        &:hover {
          opacity: 0.85;
        }
    `};
`;

const OnboardingSteps = styled.ol`
  box-sizing: border-box;
  list-style-position: inside;
  padding-left: 0;
`;

const OnboardingStep = styled.li`
  box-sizing: border-box;
  padding: 2px 0;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
`;
