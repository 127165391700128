import { styled } from "styled-components";

export const List = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  padding: 15px 18px 0 18px;
`;

export const ListItem = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 6px 0 6px 27px;

  ${({ $isActiveTab, $isActiveSubTab }) =>
    $isActiveTab &&
    `
    ${
      $isActiveSubTab
        ? `
          margin-bottom: 10px;
          box-shadow: 0 -3px #f7f7f7;
    `
        : ""
    }
    
    background-color: #f7f7f7;
    border-radius: 10px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      border: 2px solid #f95959;
      border-radius: 10px;
      top: 7px;
      left: 16px;
      height: 12px;
    }
  `}
`;
