import React, { useContext } from "react";

import Loader from "../../../components/Loader";
import { SettingsContext } from "../context";

import { Container } from "./styles";

function Content({ isLoading }) {
  const { sidebarTabs, activeTabsPath } = useContext(SettingsContext);

  function getComponentByPath() {
    let currentLevel = sidebarTabs;

    for (let i = 0; i < activeTabsPath.length; i++) {
      const key = activeTabsPath[i];

      if (!currentLevel[key]) {
        return sidebarTabs[key].component();
      }

      if (i === activeTabsPath.length - 1) {
        return currentLevel[key].component();
      }

      currentLevel = currentLevel[key].tabs;
    }

    return null;
  }

  return (
    <Container $activeTabsPath={activeTabsPath}>
      {isLoading ? <Loader parentSize={true} /> : getComponentByPath()}
    </Container>
  );
}

export default Content;
