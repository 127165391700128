import React, { useState } from "react";

import ConnectBtn from "../../../../../ConnectBtn";

import {
  Actions,
  CloseIcon,
  Container,
  Content,
  ErrorIcon,
  ErrorMessage,
  Form,
  Input,
  InputBlock,
  Inputs,
  Label,
  Relative,
} from "./styles";

function QuestionFormModal({ megaPageData, onClose, onSave }) {
  const [topic, setTopic] = useState(megaPageData?.topic || "");
  const [question, setQuestion] = useState(megaPageData?.question || "");
  const [errorMessages, setErrorMessages] = useState({});

  const handleSubmit = (event) => {
    event?.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
    } else {
      const formData = getFormData();

      onSave(formData);
    }
  };

  const getFormData = () => ({
    ...(megaPageData?.id ? { ...megaPageData } : {}),
    topic,
    question,
  });

  const validateForm = () => {
    const errors = {};

    if (!topic) errors.topic = "Missed topic";
    if (!question) errors.question = "Missed question";

    return errors;
  };

  return (
    <Container>
      <Content>
        <CloseIcon className="close-icon" onClick={onClose}>
          <svg
            width="11"
            height="11"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          >
            <line x1="11" y1="0" x2="0" y2="11" />
            <line x1="0" y1="0" x2="11" y2="11" />
          </svg>
        </CloseIcon>

        <Form onSubmit={handleSubmit}>
          <Inputs>
            <InputBlock>
              <Label htmlFor="topic">Topic</Label>
              <Relative>
                <Input
                  id="topic"
                  name="topic"
                  value={topic}
                  onChange={(e) => {
                    setTopic(e.target.value);
                    setErrorMessages((m) => ({ ...m, topic: "" }));
                  }}
                  placeholder="Enter topic..."
                  autoComplete="off"
                />
                {!!errorMessages.topic && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.topic && (
                <ErrorMessage>{errorMessages.topic}</ErrorMessage>
              )}
            </InputBlock>
            <InputBlock>
              <Label htmlFor="question">Question</Label>
              <Relative>
                <Input
                  id="question"
                  name="question"
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                    setErrorMessages((m) => ({ ...m, question: "" }));
                  }}
                  placeholder="Enter question..."
                  autoComplete="off"
                />
                {!!errorMessages.question && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.question && (
                <ErrorMessage>{errorMessages.question}</ErrorMessage>
              )}
            </InputBlock>
          </Inputs>

          <Actions>
            <ConnectBtn
              type="submit"
              label={!megaPageData.id ? "Create Question" : "Update Question"}
            />
          </Actions>
        </Form>
      </Content>
    </Container>
  );
}

export default QuestionFormModal;
