import React from "react";

import { Item, Title } from "./styles";

function Tab({
  tabName,
  title,
  handleClick,
  isActiveTab,
  isDisableTab = null,
  disabledContentTooltip = null,
  styles,
}) {
  const { lineHeight } = styles || {};

  return (
    <Item>
      <Title
        $isActiveTab={isActiveTab}
        onClick={handleClick}
        data-tooltip-id={isDisableTab ? `${tabName}-tooltip` : null}
        data-tooltip-content={
          isDisableTab ? disabledContentTooltip || "Coming soon" : null
        }
        $lineHeight={lineHeight}
      >
        {title}
      </Title>
    </Item>
  );
}

export default Tab;
