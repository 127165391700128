import React, { useState, useEffect } from "react";
import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from "../api/localStorage";
import { getMinExtensionVersion } from "../services/auth";
import { CHROME_STORE_EXTENSION_ADDRESS } from "../utils/constants";

import SuggestionPopup from "./SuggestionPopup";
import { sendMessageToExtension } from "../utils/postToExtension";

function CheckExtensionVersion() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onConfirm = () => {
    sendMessageToExtension({
      message: "close-iframe",
    });
    window.open(CHROME_STORE_EXTENSION_ADDRESS, "_blank");
    setIsPopupOpen(false);
  };

  const onCancel = () => {
    setIsPopupOpen(false);
  };

  const compareVersions = (minVersion) => {
    const extensionVersion = getDataFromLocalStorage("extensionVersion");

    if (!extensionVersion || !minVersion) {
      return 0;
    }

    function check(v1, v2) {
      if (v1 === v2) return 0;
      if (v1 === "") v1 = "";
      if (v2 === "") v2 = "";

      let p1 = 0,
        p2 = 0;
      let n1 = v1.length,
        n2 = v2.length;

      // find the location of the next '.' if it exists
      if (v1.indexOf(".") !== -1) {
        n1 = v1.indexOf(".");
      }
      if (v2.indexOf(".") !== -1) {
        n2 = v2.indexOf(".");
      }

      // create the sub-version string
      let str1 = "",
        str2 = "";
      while (p1 < n1) str1 += v1[p1++];
      while (p2 < n2) str2 += v2[p2++];

      // convert the subversion string to integer to match it
      let st1 = 0,
        st2 = 0;
      if (str1.length > 0) st1 = parseInt(str1);
      if (str2.length > 0) st2 = parseInt(str2);

      // check according to the given condition
      if (st1 < st2) return -1;
      else if (st1 > st2) return 1;

      if (n1 < v1.length) v1 = v1.substring(n1 + 1);
      else v1 = "";

      if (n2 < v2.length) v2 = v2.substring(n2 + 1);
      else v2 = "";

      // if the sub version strings are equal, then check for the next part using recursion
      // say 1.001 and 1.02
      // 1 and 1 are equal so now check 001 and 02
      return check(v1, v2);
    }

    return check(extensionVersion, minVersion);
  };

  useEffect(() => {
    const checkExtensionVersion = async () => {
      const cachedVersion = getObjectFromLocalStorage("MinExtensionVersion");

      if (cachedVersion && Date.now() < cachedVersion.timestamp) {
        const compareResult = compareVersions(cachedVersion.value);
        if (compareResult === -1) {
          setIsPopupOpen(true);
        }
        return;
      } else {
        const result = await getMinExtensionVersion();

        const newVersion = result.result.version;
        if (!newVersion) {
          return;
        }
        const expirationTime = Date.now() + 3600000;

        saveObjectToLocalStorage("MinExtensionVersion", {
          value: newVersion,
          timestamp: expirationTime,
        });

        const compareResult = compareVersions(newVersion);
        if (compareResult === -1) {
          setIsPopupOpen(true);
        }
      }
    };

    checkExtensionVersion();
  }, []);
  return (
    <>
      {isPopupOpen && (
        <SuggestionPopup
          title="Your extension needs an update"
          description="Please update the extension to the latest version to ensure that Reach works as intended."
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmBtnText="Update"
          cancelBtnText="Cancel"
        ></SuggestionPopup>
      )}
    </>
  );
}

export default CheckExtensionVersion;
