import { useState, useEffect, useCallback } from "react";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import {
  createMegaPage,
  getMegaPages,
  updateMegaPage,
  deleteMegaPage,
} from "../../../../services/megaPage";

export function useMegaPageData() {
  const [state, setState] = useState({
    isLoading: false,
    megaPageData: [],
    selectedData: null,
  });

  const setLoading = (isLoading) => {
    setState((prevState) => ({ ...prevState, isLoading }));
  };

  const saveMegaPageData = (updater) => {
    setState((prevState) => ({
      ...prevState,
      megaPageData: updater(prevState.megaPageData),
    }));
  };

  const handleError = useCallback((message, defaultMessage) => {
    setLoading(false);
    sendMessageToExtension({
      message: "show-error-message",
      data: {
        message: message || defaultMessage,
      },
    });
  }, []);

  const fetchMegaPages = useCallback(async () => {
    setLoading(true);

    const result = await getMegaPages();

    if (!result?.success) {
      handleError(result.message, "Failed to fetch Mega Pages");
      return;
    }

    saveMegaPageData((prevState) => [...prevState, ...result.megaPages]);
    setLoading(false);
  }, [handleError]);

  useEffect(() => {
    fetchMegaPages();
  }, [fetchMegaPages]);

  const handleCreateMegaPageData = async (data) => {
    setLoading(true);

    const result = await createMegaPage(data);

    if (!result?.success) {
      handleError(result.message, "Mega Page was not created");
      return;
    }

    saveMegaPageData((prevState) => [...prevState, result.result.seoData]);
    setLoading(false);
  };

  const handleUpdateMegaPageData = async (data) => {
    if (!data?.id) {
      handleError({}, "Invalid Mega Page data");
      return;
    }

    setLoading(true);

    const result = await updateMegaPage(data.id, data);

    if (!result?.success) {
      handleError(result, "Mega Page was not updated");
      return;
    }

    const { seoData } = result.result;

    saveMegaPageData((prevState) =>
      prevState.map((item) =>
        item.id === seoData.id ? { ...item, ...seoData } : item
      )
    );
    setLoading(false);
  };

  const handleSaveMegaPageData = (data) => {
    setState((prevState) => ({ ...prevState, selectedData: null }));

    data.id ? handleUpdateMegaPageData(data) : handleCreateMegaPageData(data);
  };

  const handleRegenerateMegaPageData = async (event, data) => {
    event?.preventDefault();

    setLoading(true);

    await handleUpdateMegaPageData(data);

    setLoading(false);
  };

  const handleRemoveMegaPageData = async (event) => {
    event?.preventDefault();

    setLoading(true);

    const result = await deleteMegaPage({ id: state.selectedData.id });

    if (!result?.success) {
      handleError(result.message, "Mega Page didn't remove");
      return;
    }

    saveMegaPageData((prevState) =>
      prevState.filter((megaPage) => megaPage.id !== state.selectedData.id)
    );
    setState((prevState) => ({ ...prevState, selectedData: null }));
    setLoading(false);
  };

  return {
    state,
    setState,
    handleSaveMegaPageData,
    handleRegenerateMegaPageData,
    handleRemoveMegaPageData,
  };
}

export function useModal(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return { isOpen, open, close };
}
