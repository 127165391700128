import React, { useContext } from "react";

import Tab from "../Tab";
import { SettingsContext } from "../../../context";
import { Tooltip } from "react-tooltip";

import { List, ListItem } from "./styles";

function SubTabGroup({ tabs, handleClick, parentPath }) {
  const { activeTabsPath } = useContext(SettingsContext);

  const getDeepestFirstTab = (tabObject) => {
    let path = [];
    let currentTab = Object.keys(tabObject)[0];
    path.push(currentTab);

    while (tabObject[currentTab]?.tabs) {
      tabObject = tabObject[currentTab].tabs;
      currentTab = Object.keys(tabObject)[0];
      path.push(currentTab);
    }

    return path;
  };

  return (
    <List>
      {Object.keys(tabs).map((tabName) => {
        const subTabs = tabs[tabName]?.tabs;
        const hasSubTabs = subTabs && Object.keys(subTabs).length > 0;
        const fullPath = [...parentPath, tabName];

        if (hasSubTabs) {
          fullPath.push(...getDeepestFirstTab(subTabs));
        }

        const isActiveTab = activeTabsPath.includes(tabName);
        const isDisableTab = tabs[tabName].disabledContent;
        const showSubTabs = hasSubTabs && isActiveTab;

        return (
          <React.Fragment key={tabName}>
            <ListItem $isDisableTab={isDisableTab}>
              <Tab
                tabName={tabName}
                title={tabs[tabName].title}
                handleClick={() =>
                  !isDisableTab ? handleClick(tabName, fullPath) : null
                }
                isActiveTab={isActiveTab}
                isDisableTab={isDisableTab}
                disabledContentTooltip={tabs[tabName].disabledContentTooltip}
                styles={{
                  lineHeight: "14.4px",
                }}
              />
              {showSubTabs && (
                <SubTabGroup
                  tabs={subTabs}
                  handleClick={handleClick}
                  parentPath={[...parentPath, tabName]}
                />
              )}
            </ListItem>
            {isDisableTab ? (
              <Tooltip
                id={`${tabName}-tooltip`}
                place="right"
                className="custom-tooltip"
              />
            ) : null}
          </React.Fragment>
        );
      })}
    </List>
  );
}

export default SubTabGroup;
