import React from "react";

import MegaPageRow from "./Row";

import { EmptyTableData, TableBody, TableRow } from "./styles";

function Body({ isLoading, megaPageContent, onEdit, onRemove, onRegenerate }) {
  return (
    <TableBody>
      {!megaPageContent.length && !isLoading ? (
        <TableRow>
          <EmptyTableData colSpan={100}>
            No Mega Page data to show
          </EmptyTableData>
        </TableRow>
      ) : (
        megaPageContent.map((item) => (
          <MegaPageRow
            key={item.id}
            content={item}
            onEdit={onEdit}
            onRemove={onRemove}
            onRegenerate={onRegenerate}
          />
        ))
      )}
    </TableBody>
  );
}

export default Body;
