import { styled } from "styled-components";

export const List = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  padding: 7px 18px 0 0;
`;

export const ListItem = styled.li`
  cursor: pointer;
  position: relative;
  padding: 7px 0 7px 27px;
  display: flex;
  flex-direction: column;
  color: ${({ $isDisableTab }) => ($isDisableTab ? "#bbb" : "#000")};
`;
