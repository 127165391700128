import { styled } from "styled-components";

export const Container = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const Content = styled.div`
  position: relative;
  width: 70%;
  max-width: 500px;
  margin: 0 auto;
  height: fit-content;
  max-height: 70vh;
  margin-top: 14vh;
  background: #fff;
  border-radius: 4px;
  padding: 20px 20px 20px 10px;
  border: 1px solid #999;
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -13px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(70vh - 80px);
  overflow: auto;
`;

export const Inputs = styled.div`
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

export const Relative = styled.div`
  position: relative;
`;

export const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  box-shadow: 0 0 10px #dfdfdf;

  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

export const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
  background: #fff;
`;

export const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

export const Actions = styled.div`
  display: flex;
  margin-left: 10px;
  padding-top: 20px;
  position: sticky;
  bottom: 0;
  background: #fff;
`;
