import { styled } from "styled-components";

export const Item = styled.p`
  display: flex;
`;

export const Title = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
  width: 100%;

  ${({ $isActiveTab }) =>
    $isActiveTab &&
    `
    font-weight: 700;
  `}

  line-height: ${({ $lineHeight }) => $lineHeight};
`;
