import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Actions,
  Form,
  Input,
  InputBlock,
  Inputs,
  Label,
  LinkText,
  Relative,
  SignInRedirectBlock,
  SignInRedirectText,
  Title,
} from "../components/authStyles";
import AuthLayout from "../components/AuthLayout";
import ConnectBtn from "../components/ConnectBtn";
import Loader from "../components/Loader";
import { verifyEmail, sendVerificationCode } from "../services/auth";
import { sendMessageToExtension } from "../utils/postToExtension";
import { loadUserData } from "../services/user";
import { refreshAccessToken } from "../api/axios";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import {
  getObjectFromLocalStorage,
  removeDataFromLocalStorage,
} from "../api/localStorage";
import { createIntegration } from "../services/integrations";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const user = getUserFromLocalStorage();
  const [isFirstCheckLoading, setIsFirstCheckLoading] = useState(
    user?.verified || false
  );
  const hubspotIntegrationData = getObjectFromLocalStorage(
    "hubspotIntegrationData"
  );
  const companyInfo = getObjectFromLocalStorage("companyInfo");

  const handleSuccess = async () => {
    if (user.role === "company_admin" && hubspotIntegrationData) {
      const result = await createIntegration(hubspotIntegrationData);

      if (!result.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: result.message,
          },
        });
      }

      removeDataFromLocalStorage("hubspotIntegrationData");
      navigate("/campaigns");
      sendMessageToExtension({
        message: "change-iframe-window-type",
        data: {
          iframeWindowType: "drawer",
        },
      });
    } else if (
      user.role === "company_admin" &&
      !hubspotIntegrationData &&
      companyInfo?.company?.needToConfirmPlan
    ) {
      navigate("/subscription-plans");
    } else {
      navigate("/campaigns");
      sendMessageToExtension({
        message: "change-iframe-window-type",
        data: {
          iframeWindowType: "drawer",
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const result = await verifyEmail({ code });
    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      return;
    }

    await handleSuccess();
  };

  const resendCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const result = await sendVerificationCode();
    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      return;
    }
  };

  useEffect(() => {
    const checkIsVerified = () => {
      try {
        loadUserData(
          null,
          async (res) => {
            console.log("user", res.success, res.result.verified);
            if (res.success && res.result.verified) {
              await refreshAccessToken();
              await handleSuccess();
            } else {
              setIsFirstCheckLoading(false);
            }
          },
          true
        );
      } catch {
        setIsFirstCheckLoading(false);
        console.log("Error: could not get user");
      }
    };

    const checkInterval = setInterval(checkIsVerified, 10000);

    checkIsVerified();

    return () => {
      clearInterval(checkInterval);
    };
  }, []);

  return isFirstCheckLoading ? (
    <Loader parentSize={true} />
  ) : (
    <AuthLayout isShowSwitchAuthPageSection={false}>
      <section>
        <Title>Sign Up</Title>
        <Form onSubmit={handleSubmit}>
          <Inputs>
            <InputBlock>
              <Label htmlFor="email">
                We've emailed you a verification code. Please enter it below.  If you don't see it, please check your SPAM folder.
              </Label>
              <Relative>
                <Input
                  id="code"
                  placeholder="Verification Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value.trim())}
                  autoComplete="off"
                />
              </Relative>
            </InputBlock>
          </Inputs>
          <Actions>
            <ConnectBtn
              large={true}
              type="submit"
              label="Verify"
              disabled={isLoading}
            />
            <SignInRedirectBlock>
              <SignInRedirectText>Haven't received it?</SignInRedirectText>
              <LinkText onClick={resendCode}>Click to resend</LinkText>
            </SignInRedirectBlock>
          </Actions>
        </Form>
      </section>
    </AuthLayout>
  );
};

export default VerifyEmailPage;
