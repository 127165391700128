import styled from "styled-components";

export const Container = styled.div`
  width: 87.5%;
  background-color: #fff;
  padding: ${({ $activeTabsPath }) =>
    $activeTabsPath.includes("account") ||
    $activeTabsPath.includes("emailSignature") ||
    $activeTabsPath.includes("usage") ||
    $activeTabsPath.includes("myTeam") ||
    $activeTabsPath.includes("unsubscribe")
      ? "0"
      : "43px 0 43px 63px"};
  overflow: auto;
  border-top-right-radius: 10px;
`;
