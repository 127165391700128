const REASON_OPTIONS = [
  { label: "Request an invite code", value: "Request an invite code" },
  { label: "Press Inquiry", value: "Press Inquiry" },
  { label: "Suggestion", value: "Suggestion" },
  { label: "General Support", value: "General Support" },
];

const ENVs = {
  local: "http://localhost:3000",
  dev: "https://dev-web.salesstream.ai",
  qa: "https://qa-web.salesstream.ai",
  prod: "https://web.salesstream.ai",
};

const CRMS = {
  EXCEL_ONLINE: "excel-online",
  GOOGLE_SHEETS: "google-sheets",
  BULLHORN: "bullhorn",
  HUBSPOT: "hubspot",
};

const SPREADSHEET_TYPES = [CRMS.EXCEL_ONLINE, CRMS.GOOGLE_SHEETS];

const CRM_LABELS = {
  [CRMS.BULLHORN]: "Bullhorn",
  [CRMS.HUBSPOT]: "HubSpot",
  [CRMS.GOOGLE_SHEETS]: "Google Sheets",
  [CRMS.EXCEL_ONLINE]: "Excel online",
};

const CRM_OPTIONS = [
  { value: CRMS.BULLHORN, label: CRM_LABELS[CRMS.BULLHORN] },
  { value: CRMS.HUBSPOT, label: CRM_LABELS[CRMS.HUBSPOT] },
  { value: CRMS.GOOGLE_SHEETS, label: CRM_LABELS[CRMS.GOOGLE_SHEETS] },
  { value: CRMS.EXCEL_ONLINE, label: CRM_LABELS[CRMS.EXCEL_ONLINE] },
];

const DEFAULT_GROUP_OPTION = {
  value: "any",
  label: "Any",
};

const FAQ_QUESTIONS = {
  generalInfo: {
    title: "General Info",
    questions: [
      {
        title: "What is Reach and who is it for?",
        answer: [
          "Reach by SalesStream.ai is a free Chrome extension that can plugin to your spreadsheet or CRMs like Hubspot to help you quickly write and send personalized emails and SMS, drawing data from all contact fields including your history of interactions with each contact. Reach has been purpose built to help small business owners, solopreneurs, freelancers and everyone else who wants to create effective email and SMS campaigns without any marketing expertise. ",
        ],
      },
      {
        title: "How do I get started with Reach?",
        answer: [
          "Kickstart your experience by downloading the Reach Chrome extension from the Chrome Web Store. After a straightforward installation and CRM/email system integration, you're all set for an enhanced email outreach experience.",
        ],
      },
      {
        title: "Is Reach completely free?",
        answer: [
          "Reach is a completely free Chrome Extension with 500 AI credits every month. You can upgrade the Starter pack to get 7,500 AI credits per month and professional packages to get 30,000 AI credits per month",
        ],
      },
      {
        title: "Does Reach work in languages other than English?",
        answer: [
          "Reach's UI is geared toward an English-speaking audience.  However, campaign messages can be generated in over 30 languages.  You can experiment with this by telling Reach to output messages in another language within the 'Additional Instructions' campaign option. Please let us know if you have a use case for re-skinning the Reach UI for a different language by emailing us at support@salesstream.ai ",
        ],
      },
      {
        title: "How do I ensure my email is perfect?",
        answer: [
          "While Reach is designed to generate high-quality emails, no AI can guarantee 100% perfection. We advise manually reviewing the messages prior to launching a campaign just to be sure.",
        ],
      },
      {
        title:
          "How does defining campaign goals in the SalesStreamAI campaign workflow benefit me?",
        answer: [
          "Campaign goals provide our AI with a clear directive on the desired recipient interaction and response. From common goals like scheduling calls or email replies to custom user-defined objectives, these guide the AI in crafting effective communication strategies from the initial outreach to subsequent responses.",
        ],
      },
      {
        title: "Can Reach help me generate campaign description?",
        answer: [
          "Yes, Reach includes a 'Generate this for me' button on the Campaign Details page. Answer some basic questions about what you're trying to accomplish and Reach will do the rest.",
        ],
      },
      {
        title: "Do Reach campaigns only work with email?",
        answer: [
          "No. Reach currently supports email and SMS (via Twilio).  We are also actively exploring expansion into AI-generated voice and video communication.",
        ],
      },
      {
        title: "Which CRMs are compatible with Reach?",
        answer: [
          "Reach is currently integrated with Bullhorn and HubSpot CRMs, but we are working on incorporating other popular CRM platforms. Please reach out if you'd like to recommend a CRM for future integration.",
        ],
      },
      {
        title: "How does the Reach Chrome extension work?",
        answer: [
          "Our extension allow Reach to live within the UI of your favorite CRM or spreadsheet application.",
        ],
      },
      {
        title: "How does Reach personalize messages?",
        answer: [
          "We leverage state-of-the-art generative AI, specifically Large Language Models (LLMs). By incorporating pertinent contact data from CRMs and spreadsheets, we craft emails that resonate with each recipient, ensuring personalized communication.",
        ],
      },
      {
        title: "Can Reach handle responses to incoming emails?",
        answer: [
          "Absolutely! Our system can automatically draft and send replies to incoming messages, guaranteeing timely and relevant communication.",
        ],
      },
      {
        title: "How secure is my data?",
        answer: [
          "Your data's security is our utmost priority. We adhere to rigorous encryption standards and employ industry standard security measures to ensure your data from CRM or spreadsheets remains strictly confidential and is utilized exclusively for email customization.  Please check out our Terms of Use and Privacy Policy for more details.",
        ],
      },
      {
        title: "What type of support is available for users?",
        answer: [
          "We at Reach strive to provide 24/7 support to our users. You can chat with us via our Chat widget on Reach or on our website (https://salesstream.ai/) or you can write to us at support@salesstream.ai",
        ],
      },
      {
        title: "How do I report downtime or a bug?",
        answer: [
          "Should you encounter any issues with Reach, please report them to support@salesstream.ai or chat to us using the widget at the bottom right of every screen.",
        ],
      },
      {
        title: "How do I integrate my SMS provider with Reach?",
        answer: [
          "Reach currently integrates with Twilio for SMS messaging. Set up is simple: log into Reach, navigate to Settings -> Integrations -> SMS Gateway, enter your Twilio API Key, SID, and target phone number, and connect. Let us know at support@salesstream.ai which SMS provider you would like us to integrate next.",
        ],
      },
      {
        title: "How can i check my AI credit limit and usage?",
        answer: [
          "Your AI credit limit and usage are displayed every time you create a campaign, and you can also view usage details under Settings -> Usage.",
        ],
      },
    ],
  },
  appUse: {
    title: "App Use",
    questions: [
      {
        title: "Why do I need to define campaign goals?",
        answer: [
          "Campaign goals provide our AI with a clear directive on the desired recipient interaction and response. From common goals like scheduling calls or email replies to custom user-defined objectives, Reach uses goals to provide the appropriate 'ask' within your messages and evaluate replies for campaign success metrics.",
        ],
      },
      {
        title: "What role themes play in the campaign workflow?",
        answer: [
          "The theme guides Reach in focusing on your campaign on what is most important to you.",
        ],
      },
      ,
      {
        title: "How can I integrate Reach with my email provider?",
        answer: [
          "Reach integrates seamlessly with Office365 & GSuite. Log into Reach, go to Settings -> Integrations -> Email system, select your preferred system, and click on 'Quick Connect'. Follow the prompts to sign in and authorize the necessary permissions. Remember - Reach will only use your email provider to send messages or view responses to emails generated by Reach.  Reach will never read any of your other messages.",
        ],
      },
      {
        title: "Can I schedule a campaign?",
        answer: [
          "Yes, Reach allows you to schedule campaigns, specifying the number of follow-ups, campaign duration, and exact timing for campaign initiation.",
        ],
      },
      {
        title: "Does Reach work on spreadsheets?",
        answer: [
          "Yes, Reach works flawlessly with Excel Online and Google Sheets. You can open Excel Online in your browser and use Reach to generate email and SMS campaigns. ",
        ],
      },
      {
        title: "What is the maximum number of messages I can generate?",
        answer: [
          "The number of emails you can generate for your monthly allotment of credits depends on your data and your campaign parameters.  With the free plan, you can easily generate over 100+ emails per month.  Additional credits can be purchased by upgrading to our Starter or Professional accounts, enabling larger-scale campaigns.",
        ],
      },
      {
        title:
          "What is the maximum number of contacts I can select for campaigns from a spreadsheet?",
        answer: ["There is no limit to the number of contacts you can select."],
      },
      {
        title: "Will my campaigns be launched if I run out AI credits?",
        answer: [
          "If your AI credits are low, Reach will notify you before proceeding with the campaign launch. If you choose to continue, Reach will execute your email and SMS campaigns until the credits are depleted.",
        ],
      },
      {
        title: "Can I download my campaign analytics reports?",
        answer: [
          "Real-time analytics are currently available in Reach's reports section. Future updates will allow you to export these analytics in CSV, PDF, or Excel formats.",
        ],
      },
      {
        title: "Is there a required format for data in the spreadsheet?",
        answer: [
          "No specific format is required. Reach's advanced AI capabilities enable it to effectively analyze and understand your data, even without headers in the spreadsheet.",
        ],
      },
      {
        title:
          "Can I customize the underlying LLM prompts Reach uses to generate messages?",
        answer: [
          "Power users & prompt engineering enthusiasts can use Reach's 'Prompts Playground' to fully customize the underlying LLM prompts. Join our Superuser community by emailing support@salesstream.ai for access to advanced functionality.",
        ],
      },
    ],
  },
};

const EMAIL_REGEX = /^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\-]+(\.[\w%\+\-]+)+$/;

const NOTIFICATION_TYPES = {
  EMAIL_REPLY: "email_reply",
  GOAL_COMPLETION: "goal_completion",
  OAUTH_TOKEN_EXPIRED: "oauth_token_expired",
};

const NOTIFICATION_STATUSES = {
  NEW: "new",
  READ: "was read",
};

const LOGIN_PAGE_CONTENT_LIST = {
  LOGIN: "login",
  ASK_RESET_PASSWORD: "ask-reset-password",
  RESET_PASSWORD: "reset-password",
};

const CHROME_STORE_EXTENSION_ID = "dpncdmdnpgoigoehmpkpblhdjkcdpaih";

const CHROME_STORE_EXTENSION_ADDRESS =
  "https://chromewebstore.google.com/detail/salesstreamai-reach/dpncdmdnpgoigoehmpkpblhdjkcdpaih?hl=en&authuser=1";

const DEFAULT_VALUE_FOR_MODEL = `[DEFAULT]`;
const DEFAULT_VALUE_FOR_PROMPT_COMPANY = `[DEFAULT]`;
const DEFAULT_VALUE_FOR_SYSTEM_PROMPT_CRM = `[system]`;

const USER_ROLES = {
  DEACTIVATED: "deactivated",
  UNPAID: "unpaid",
  INVITED: "invited",
  ADMIN: "admin",
  USER: "user",
  COMPANY_ADMIN: "company_admin",
};

const OAUTH_TYPES = {
  office365: "office365",
  gsuite: "gsuite",
};

const AUTO_WARM_MAILBOX_PROVIDERS = {
  yahoo: "yahoo",
  ...OAUTH_TYPES,
};

const AUTO_WARM_MAILBOX_STATUSES = {
  enabled: "Enabled",
  disabled: "Disabled",
  warmed: "Warmed",
};

const AUTO_WARM_PLACEMENT_RESULT_STATUSES = {
  pending: "PENDING",
  delivered: "DELIVERED",
};

const FSC_STEP_OFFSET_UNIT = {
  WEEK: "w",
  DAY: "d",
  HOUR: "h",
  MINUTE: "m",
};

const FSC_STEP_OFFSET_UNIT_LABEL = {
  [FSC_STEP_OFFSET_UNIT.WEEK]: "week",
  [FSC_STEP_OFFSET_UNIT.DAY]: "day",
  [FSC_STEP_OFFSET_UNIT.HOUR]: "hour",
  [FSC_STEP_OFFSET_UNIT.MINUTE]: "minute",
};

const FSC_STEP_STATUS = {
  PENDING: "Pending",
  ASSIGNED: "Assigned",
  COMPLETED: "Completed",
  SKIPPED: "Skipped",
  SUCCESS: "Success",
  OPT_OUT: "Opt out",
};

const FSC_STEP_AUTOMATION_LEVEL = {
  AUTOMATIC: "Automatic",
  AI_ASSISTED: "AI Assisted",
  MANUAL: "Manual",
};

const FSC_STEP_TYPE = {
  EMAIL: "Email",
  SMS: "SMS",
  LINKEDIN_CONNECTION_REQUEST: "LinkedIn Connection Request",
  LINKEDIN_POST_COMMENT: "LinkedIn Post Comment",
  LINKEDIN_MESSAGE_INMAIL: "LinkedIn Message (InMail)",
  LINKEDIN_POST_LIKE: "LinkedIn Post Like",
  PHONE_CALL: "Phone Call",
  MARKETING_EMAIL: "Marketing Email",
};

const INTEGRATION_TYPE = {
  EMAIL: "Email",
  SMS: "SMS",
};

const PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY = "preparedCampaignName";

const FIRST_TIME_CAMPAIGN_ONBOARDING_USER_LIST_LOCAL_STORAGE_KEY =
  "firstTimeCampaignOnboardingUserList";

const FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS = {
  createCampaign: {
    text: 'You\'re now ready to create your first campaign.  Enter a campaign title and click "Create Campaign" to give it a shot',
    offset: { top: "28%", left: "0%" },
    name: "createCampaign",
  },
  campaignOverview: {
    text: "Select the goal of your first campaign or create one if of your own.  Reach will use this as the main focus of your messages",
    offset: { top: "35%", right: "8%" },
    name: "campaignOverview",
  },
  campaignType: {
    text: 'Select a campaign type.  If the existing types aren\'t right, click "Create a new Campaign Type" and enter your own',
    offset: { top: "19%", right: "8%" },
    name: "campaignType",
  },
  campaignDetails: {
    text: "Here, you'll give Reach all the relevant data for your campaign.  The more information you can provide here, the better your outreach messages will become",
    offset: { top: "38%", right: "8%" },
    name: "campaignDetails",
  },
  salesStrategy: {
    text: "Reach has come up with some message themes based on your campaign details.  Select the one you think resonates best",
    offset: { top: "19%", right: "8%" },
    name: "salesStrategy",
  },
  selectRecipients: {
    text: "Review the contacts you've selected and remove any you many want to exclude from your campaign",
    offset: { top: "35%", left: "0%" },
    name: "selectRecipients",
  },
  configureOptions: {
    text: "Now it's time to specify your campaign options.  Tell Reach when you'd like the messages sent or give it some additional instructions",
    offset: { top: "38%", right: "8%" },
    name: "configureOptions",
  },
  prepareCampaign: {
    name: "prepareCampaign",
  },
  preparingCampaign: {
    text: "Some preview messages for your campaign are being generated.  This process may take a minute, so feel free to close the Reach window while you wait",
    offset: { top: "15%", right: "8%" },
    name: "preparingCampaign",
  },
  preparedCampaignDetails: {
    text: "You're done! You can now Review the generated messages or Launch your campaign",
    offset: { top: "-30%", right: "4%" },
    name: "preparedCampaignDetails",
  },
};

const FIRST_TIME_CAMPAIGN_TYPE_BY_USERS_LS_KEY =
  "outreachFirstTimeCampaignTypeByUsers";

const LAST_LOGIN_METHOD_LS_KEY = "outreachLastLoginMethod";

const HUBSPOT_APP_INSTALL_URL = "/install-hubspot-app";
const TEMP_LOCAL_STORAGE_KEY_NAME_COOKIE_NAME = "tempLocalStorageKeyName-";
const TEMP_LOCAL_STORAGE_KEY_NAME_LIST_COOKIE_NAME = "localStorageKeyNameList";
const SOURCE_QUERY_PARAM = "source";
const SOURCE_QUERY_VALUE = "integration";
const COOKIE_OPTIONS_STRING = `SameSite=None; Secure; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;

const SUBSCRIPTION_PLAN_NAME = {
  FREE: "Free",
  PROFESSIONAL: "Professional",
};

const SUBSCRIPTION_PLAN_PERIOD = {
  MONTH: "month",
  YEAR: "year",
};

const DEFAULT_SUBSCRIPTION_PLAN_PERIOD = SUBSCRIPTION_PLAN_PERIOD.YEAR;

const IS_FIRST_TIME_VISIT_CONFIGURE_OPTIONS_STEP_LOCAL_STORAGE_KEY =
  "isFirstTimeVisitConfigureOptionsStep";

export {
  REASON_OPTIONS,
  FAQ_QUESTIONS,
  CRM_OPTIONS,
  DEFAULT_GROUP_OPTION,
  CRMS,
  SPREADSHEET_TYPES,
  CRM_LABELS,
  EMAIL_REGEX,
  ENVs,
  NOTIFICATION_TYPES,
  NOTIFICATION_STATUSES,
  LOGIN_PAGE_CONTENT_LIST,
  CHROME_STORE_EXTENSION_ID,
  CHROME_STORE_EXTENSION_ADDRESS,
  DEFAULT_VALUE_FOR_MODEL,
  DEFAULT_VALUE_FOR_PROMPT_COMPANY,
  DEFAULT_VALUE_FOR_SYSTEM_PROMPT_CRM,
  USER_ROLES,
  OAUTH_TYPES,
  AUTO_WARM_MAILBOX_PROVIDERS,
  AUTO_WARM_MAILBOX_STATUSES,
  AUTO_WARM_PLACEMENT_RESULT_STATUSES,
  FSC_STEP_OFFSET_UNIT,
  FSC_STEP_OFFSET_UNIT_LABEL,
  FSC_STEP_STATUS,
  FSC_STEP_AUTOMATION_LEVEL,
  FSC_STEP_TYPE,
  INTEGRATION_TYPE,
  PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_USER_LIST_LOCAL_STORAGE_KEY,
  FIRST_TIME_CAMPAIGN_TYPE_BY_USERS_LS_KEY,
  LAST_LOGIN_METHOD_LS_KEY,
  HUBSPOT_APP_INSTALL_URL,
  SOURCE_QUERY_PARAM,
  SOURCE_QUERY_VALUE,
  COOKIE_OPTIONS_STRING,
  TEMP_LOCAL_STORAGE_KEY_NAME_COOKIE_NAME,
  TEMP_LOCAL_STORAGE_KEY_NAME_LIST_COOKIE_NAME,
  SUBSCRIPTION_PLAN_NAME,
  SUBSCRIPTION_PLAN_PERIOD,
  DEFAULT_SUBSCRIPTION_PLAN_PERIOD,
  IS_FIRST_TIME_VISIT_CONFIGURE_OPTIONS_STEP_LOCAL_STORAGE_KEY,
};
