import {
  fetchGetMegaPage,
  fetchCreateMegaPage,
  fetchUpdateMegaPage,
  fetchDeleteMegaPage,
} from "./queries.js";

const getMegaPages = async () => {
  try {
    const result = await fetchGetMegaPage();

    if (result.message) {
      return result;
    }

    return { success: true, megaPages: result.seoData };
  } catch (error) {
    return { success: false, message: "Get Mega Page failed" };
  }
};

const createMegaPage = async (data) => {
  try {
    const result = await fetchCreateMegaPage(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Create Mega Page failed" };
  }
};

const updateMegaPage = async (id, data) => {
  try {
    const result = await fetchUpdateMegaPage(id, data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Update Mega Page failed" };
  }
};

const deleteMegaPage = async (data) => {
  try {
    const result = await fetchDeleteMegaPage(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Delete Mega Page failed" };
  }
};

export { getMegaPages, createMegaPage, updateMegaPage, deleteMegaPage };
