import React, { useCallback } from "react";

import Loader from "../../../../Loader";
import TableContent from "../Table";
import QuestionModalContent from "../QuestionModalContent";
import ConfirmModalContent from "../ConfirmModalContent";
import { useMegaPageData, useModal } from "../hooks";

import {
  Container,
  Content,
  Title,
  NewQuestionButton,
  LoaderWrapper,
  OverflowContent,
} from "./styles";

function MegaPageWrapper() {
  const {
    state,
    setState,
    handleSaveMegaPageData,
    handleRegenerateMegaPageData,
    handleRemoveMegaPageData,
  } = useMegaPageData();

  const questionModal = useModal(false);
  const confirmModal = useModal(false);

  const handleOpenQuestionModal = useCallback(
    (event, data) => {
      event?.preventDefault();

      if (data?.id) {
        const currentMegaPageData = state.megaPageData.find(
          (item) => item.id === data.id
        );

        if (!currentMegaPageData) return;

        setState((prevState) => ({
          ...prevState,
          selectedData: currentMegaPageData,
        }));
      } else {
        setState((prevState) => ({ ...prevState, selectedData: {} }));
      }

      questionModal.open();
    },
    [state.megaPageData, setState, questionModal]
  );

  const handleOpenConfirmModal = useCallback(
    (event, data) => {
      event?.preventDefault();
      setState((prevState) => ({ ...prevState, selectedData: data }));
      confirmModal.open();
    },
    [setState, confirmModal]
  );

  return (
    <Container>
      <Content>
        <Title>Mega Page</Title>

        <NewQuestionButton
          onClick={handleOpenQuestionModal}
          disabled={state.isLoading}
        >
          New Question
        </NewQuestionButton>

        <QuestionModalContent
          state={state}
          questionModal={questionModal}
          setState={setState}
          handleSaveMegaPageData={handleSaveMegaPageData}
        />

        <ConfirmModalContent
          state={state}
          confirmModal={confirmModal}
          setState={setState}
          handleRemoveMegaPageData={handleRemoveMegaPageData}
        />

        {state.isLoading && (
          <LoaderWrapper>
            <Loader parentSize={true} padding="0" />
          </LoaderWrapper>
        )}

        <OverflowContent>
          <TableContent
            isLoading={state.isLoading}
            megaPageData={state.megaPageData}
            onEdit={handleOpenQuestionModal}
            onRegenerate={handleRegenerateMegaPageData}
            onRemove={handleOpenConfirmModal}
          />
        </OverflowContent>
      </Content>
    </Container>
  );
}

export default MegaPageWrapper;
