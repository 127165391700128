import React from "react";

import theme from "../../../../../theme";
import { TableRow, TableData, Actions, ActionButton } from "./styles";

const Row = ({ content, onEdit, onRemove, onRegenerate }) => {
  const { id, topic, question, answer } = content;

  return (
    <TableRow
      key={id}
      $borderBottom={`1px solid ${theme.colors.divider_color}`}
    >
      <TableData>{topic}</TableData>
      <TableData>{question}</TableData>
      <TableData>{answer}</TableData>
      <TableData>
        <Actions>
          <ActionButton
            className="button button-primary"
            onClick={(event) => onEdit(event, content)}
          >
            Edit
          </ActionButton>
          <ActionButton
            className="button button-primary"
            onClick={(event) => onRegenerate(event, content)}
          >
            Regenerate
          </ActionButton>
          <ActionButton
            className="button button-primary"
            onClick={(event) => onRemove(event, content)}
          >
            Delete
          </ActionButton>
        </Actions>
      </TableData>
    </TableRow>
  );
};

export default Row;
