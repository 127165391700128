import ConfirmPopup from "../../../../ConfirmPopup";

const ConfirmModalContent = ({
  state,
  confirmModal,
  setState,
  handleRemoveMegaPageData,
}) => {
  if (!state.selectedData || !confirmModal.isOpen) return null;

  return (
    <ConfirmPopup
      title="Are you sure?"
      description="Are you sure you want to delete this question?"
      onConfirm={() => {
        confirmModal.close();
        handleRemoveMegaPageData();
      }}
      onCancel={() => {
        confirmModal.close();
        setState((prevState) => ({ ...prevState, selectedData: null }));
      }}
      confirmLabel="Yes, delete"
      cancelLabel="Cancel"
    />
  );
};

export default ConfirmModalContent;
