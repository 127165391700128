import React, { useState } from "react";

import TableHeader from "./TableHeader";
import BlogPostRow from "./BlogPostRow";
import { sortData } from "../utils";

import { Table, TableBody, EmptyTableData, TableRow } from "./styles";

function TableContent({
  blogPosts,
  onEdit,
  onRemove,
  onToggleActive,
  onOpenLinkPopup,
}) {
  const [sort, setSort] = useState({ sort: null, order: null });

  const withSort = blogPosts.length > 1;
  const sortedBlogPosts = withSort
    ? sortData(blogPosts, sort.sort, sort.order)
    : blogPosts;

  const handleClickSort = (sortField) => {
    setSort((curSort) => {
      if (sortField === curSort.sort) {
        if (curSort.order === "asc") {
          return { ...curSort, order: "desc" };
        }
        return { sort: null, order: null };
      }
      return { sort: sortField, order: "asc" };
    });
  };

  return (
    <Table>
      <TableHeader onSort={handleClickSort} sort={sort} withSort={withSort} />
      <TableBody>
        {!sortedBlogPosts.length ? (
          <TableRow>
            <EmptyTableData colSpan={100}>No post to show</EmptyTableData>
          </TableRow>
        ) : (
          sortedBlogPosts.map((post) => (
            <BlogPostRow
              key={post.id}
              post={post}
              onEdit={onEdit}
              onRemove={onRemove}
              onToggleActive={onToggleActive}
              onOpenLinkPopup={onOpenLinkPopup}
            />
          ))
        )}
      </TableBody>
    </Table>
  );
}

export default TableContent;
