import React, { useContext } from "react";

import { SettingsContext } from "../../context";
import TabsGroup from "./TabGroup";

function TabList() {
  const { fetchIntegrations, setActiveTabsPath } = useContext(SettingsContext);

  const handleTabClick = (key, path) => {
    if (key === "integrations") {
      fetchIntegrations();
    }

    setActiveTabsPath(path);
  };

  return <TabsGroup handleClick={handleTabClick} />;
}

export default TabList;
