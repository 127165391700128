import React from "react";
import { styled } from "styled-components";

function SuggestionPopup({
  title,
  description,
  biggerHeight,
  onConfirm,
  onCancel,
  children,
  confirmBtnText,
  cancelBtnText,
  imageSrc,
  imageHeight,
  removeCancel,
  styles,
  noOverlay,
}) {
  const { titleStyle } = styles || {};

  return (
    <>
      <ConfirmPopupContainer $biggerHeight={biggerHeight}>
        <img
          src={imageSrc ? imageSrc : "/images/lamp.png"}
          alt="lamp"
          height={imageHeight || 71}
        />
        <PopupTitle $titleStyle={titleStyle}>{title}</PopupTitle>
        <PopupDescription>{description}</PopupDescription>
        {children}
        <PopupActionButtons>
          <ConfirmButton onClick={onConfirm} $removeCancel={removeCancel}>
            {confirmBtnText ? confirmBtnText : "Sounds good!"}
          </ConfirmButton>
          {!removeCancel && (
            <CancelButton onClick={onCancel}>
              {cancelBtnText ? cancelBtnText : "No thanks"}
            </CancelButton>
          )}
        </PopupActionButtons>
      </ConfirmPopupContainer>
      {!noOverlay && <Overlay />}
    </>
  );
}

export default SuggestionPopup;

const ConfirmPopupContainer = styled.div`
  top: calc(
    (100vh - ${({ $biggerHeight }) => ($biggerHeight ? "350px" : "290px")}) / 2
  );
  ${({ $biggerHeight }) =>
    $biggerHeight ? "min-height: 350px" : "height: 290px"};

  position: fixed;
  left: calc((100vw - 479px) / 2);
  width: 479px;
  border-radius: 6px;
  border: 1px solid #cbcbcb;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 37px 20px;
  z-index: 1000;
  box-shadow: 0px 0px 20px 0px #0000001a;
  background: linear-gradient(180deg, rgb(255, 251, 241), rgb(255, 255, 255));
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const PopupTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: "AlbertSansExtraBold", sans-serif;
  margin-top: 15px;
  color: ${({ $titleStyle }) =>
    $titleStyle?.fontColor ? $titleStyle?.fontColor : "#000"};
`;

const PopupDescription = styled.div`
  margin: 5px 0;
  font-size: 12px;
  font-weight: 400;
  font-family: "AlbertSans", sans-serif;
  color: #475467;
  text-align: center;
  width: 90%;
  word-break: break-word;
  white-space: pre-wrap;
`;

const PopupActionButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

const ConfirmButton = styled.button`
  background-color: #f95959;
  height: 40px;
  padding: 10px 29px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  font-family: "AlbertSansExtraBold", sans-serif;
  border-radius: 6px;
  border: none;
  cursor: pointer;
`;

const CancelButton = styled.div`
  color: black;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
`;
